import React, { useEffect } from 'react'
import { motion, useAnimation, } from "framer-motion"
import { getMaskStaticPathWithAspectRatio } from "../../utils/staticPathsForMaskImages"
import { configCircleAndAroundCirclesPathsD, svgCirclePathDRelativeToCenter, svgPathsDRelativeToCenterOfCirclesAround, svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround, SvgWithPathsOfOuterCircleAndSmallCirclesAround } from "../../utils/calculateSvgPath"
import { useTheme } from '@mui/material/styles'

const defaultOptions = {
    nameOfPaths: "circleAndCirclesAround",
    clipPathId: "divCirclesAroundId",
    maskName: "mask11",
    diameter: 100,
    pointsNumber: 10,
    styleDiv: { backgroundColor: "green" },
    divMinHeight: "200px",
    isAnimation: true,
}

const DivWithCircleAndCirclesAroundClipPathAnimation = (props) => {
    const mergedOptions = { ...defaultOptions, ...props }
    const {
        nameOfPaths,
        clipPathId,
        maskName,
        diameter,
        pointsNumber,
        styleDiv,
        divMinHeight,
        isAnimation,
        // bgImageGradient = 'radial-gradient(circle at 50% 50%, #770303 0%, #d30101 25%, transparent 75%, transparent 100%)'
        bgImageFromGradientColor1, // = "rgb(119, 3, 3)",
        bgImageFromGradientColor2, // = "rgb(211, 1, 1)",
        bgImageToGradientColor1, // = "rgb(85, 6, 6)",
        bgImageToGradientColor2, // = "rgb(21, 229, 104)",
    } = mergedOptions

    const theme = useTheme();
    const usedBgImageFromGradientColor1 = bgImageFromGradientColor1 ? bgImageFromGradientColor1 : theme.palette.info.main
    const usedBgImageFromGradientColor2 = bgImageFromGradientColor2 ? bgImageFromGradientColor2 : theme.palette.secondary.main
    const usedBgImageToGradientColor1 = bgImageToGradientColor1 ? bgImageToGradientColor1 : theme.palette.secondary2.main
    const usedBgImageToGradientColor2 = bgImageToGradientColor2 ? bgImageToGradientColor2 : theme.palette.warning.main

    const ctrlOuterCircle = useAnimation()
    const ctrlAroundCircle = useAnimation()
    const ctrlDiv = useAnimation()
    const pathsD = configCircleAndAroundCirclesPathsD[ nameOfPaths ];

    const { dOuterCircle, dsCirclesAround, dOuterCircleStart, dsCirclesAroundStart } = pathsD
    // const dOuterCircle = svgCirclePathDRelativeToCenter({ diameter, outerCirclePercentage })
    // const dsCirclesAround = svgPathsDRelativeToCenterOfCirclesAround({ diameter, pointsNumber, distanceAroundCircleFromCenterPercentage, radiusAroundCircle })
    const bgImageToGradient = `radial-gradient(circle at 50% 50%, ${usedBgImageToGradientColor1} 0%, ${usedBgImageToGradientColor2} 60%, transparent 100%)`

    useEffect(() => {
        if (!isAnimation) return
        const seq1 = async () => {

            ctrlAroundCircle.start(({ i }) => ({
                d: dsCirclesAround[ i ],
                // fillOpacity: 0,
                // backgroundSize:"100%",
                transition: {
                    type: "spring", stiffness: 100,
                    // duration: 2,
                    // ease: "easeInOut",
                    delay: i * 0.5
                }
            })
            )

            ctrlOuterCircle.start(() => ({
                d: dOuterCircle,
                // fillOpacity: 0,
                transition: {
                    type: "spring", stiffness: 100,
                    // duration: 2,
                    // ease: "easeInOut",
                    delay: (pointsNumber - 1) * 0.5
                }
            })
            )
            ctrlDiv.start(() => ({

                backgroundImage: bgImageToGradient,// 'radial-gradient(circle at 50% 50%, #580303 0%, #530101 25%, transparent 75%, transparent 100%)',
                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 0.5,
                    ease: "easeInOut",
                    delay: (pointsNumber - 1) * 0.5 //+ 1

                }
            }))
            ctrlDiv.start(() => ({
                // fillOpacity: 0,
                // backgroundSize: "100%",
                maskSize: "20%",
                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 0.5,
                    ease: "easeInOut",
                    delay: (pointsNumber - 1) * 0.5 + 0.5

                }
            }))
            // ctrlDiv.start(() => ({

            //     maskPosition: "50%",
            //     transition: {
            //         // type: "spring", stiffness: 100,
            //         duration: 0.5,
            //         ease: "easeInOut",
            //         delay: pointsNumber + 4.5

            //     }
            // }))
        }
        seq1()
        return () => {
            ctrlAroundCircle.stop()
        }
    }, [ isAnimation, ctrlAroundCircle, ctrlOuterCircle, ctrlDiv, dOuterCircle, dsCirclesAround, bgImageToGradient, pointsNumber ])

    const staticMaskImgPath = getMaskStaticPathWithAspectRatio(maskName).path
    const bgImageFromGradient = `radial-gradient(circle at 50% 50%, ${usedBgImageFromGradientColor1} 0%, ${usedBgImageFromGradientColor2} 60%, transparent 100%)`

    // const { dOuterCircle, dsCirclesAround } = svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround()
    // const dOuterCircleStart = svgCirclePathDRelativeToCenter({ diameter, outerCirclePercentage: 0 })
    // const dsCirclesAroundStart = svgPathsDRelativeToCenterOfCirclesAround({ diameter, pointsNumber, distanceAroundCircleFromCenterPercentage: 0, radiusAroundCircle: 0 })
    // console.log("dOuterCircle", dOuterCircle)
    // console.log("dsCirclesAround", dsCirclesAround)
    const usedDsAround = isAnimation ? dsCirclesAroundStart : dsCirclesAround
    return (
        <>
            <svg width="100" height="100" viewBox="0 0 100 100" style={{ position: 'absolute' }}>

                <defs>
                    <clipPath id={clipPathId}
                        clipPathUnits="objectBoundingBox"
                    >
                        <motion.path
                            // d={clipPathFrom}
                            d={isAnimation ? dOuterCircleStart : dOuterCircle}
                            animate={ctrlOuterCircle}
                            // fill="#FFFFFF"
                            // fill="#000000"
                            stroke="#000000" strokeMiterlimit="10"
                            strokeWidth="50"
                        // style={{
                        //     fillOpacity: 0.5,
                        //     fill: "black"
                        // }}
                        />
                        {usedDsAround.map((d, i) => (
                            <motion.path
                                key={`around-${i}`}
                                d={d}
                                animate={ctrlAroundCircle}
                                custom={{ i }}
                                // fillOpacity={0.5}
                                // style={{
                                //     fill: "black",
                                //     fillOpacity: 0.5
                                // }}
                                stroke="#000000" strokeMiterlimit="10"
                            />
                        ))}
                    </clipPath>
                </defs>

            </svg>
            <motion.div
                animate={ctrlDiv}
                style={{
                    clipPath: `url(#${clipPathId})`,
                    // maskImage: mask,
                    // backgroundImage: 'radial-gradient(circle at 50% 50%, #770303 0%, #d30101 25%, transparent 75%, transparent 100%)',
                    backgroundImage: isAnimation ? bgImageFromGradient : bgImageToGradient,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "center",
                    WebkitMaskImage: `url(${staticMaskImgPath})`,
                    maskImage: `url(${staticMaskImgPath})`,
                    minHeight: divMinHeight,
                    // backgroundSize: isAnimation ? '1%' : '100%',
                    maskSize: isAnimation ? '100%' : '20%',
                    maskPosition: '50%',
                    // backgroundAttachment: 'fixed',
                    // backgroundColor: "green",
                    // height: "200px",
                    // maxHeight: { maxHeight },
                    // width: '100%',
                    // // WebkitMaskImage: 'radial-gradient(circle at 50% 50%, transparent 0%, transparent 25%, black 75%, black 100%)', // For Safari
                    ...styleDiv,
                }}
            >

            </motion.div>
        </>
    )
}
export default DivWithCircleAndCirclesAroundClipPathAnimation
